.dashboard {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.dashboard::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('https://t3.ftcdn.net/jpg/03/83/21/14/360_F_383211493_IeDoiYIgUhpSU1I0JQcxngxKWTlF1RnA.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.6;
  z-index: -2;
}

.dashboard::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.dashboard-container {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

h1 {
  color: #1e3c72;
  text-align: center;
  margin-bottom: 30px;
}

.input-form {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
}

.url-input {
  flex: 1;
  padding: 15px;
  border: 2px solid #e0e0e0;
  border-radius: 10px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.url-input:focus {
  border-color: #1e3c72;
  outline: none;
}

.preview-btn, .download-btn {
  padding: 15px 30px;
  background: #1e3c72;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;
}

.preview-btn:hover, .download-btn:hover {
  background: #2a5298;
}

.error {
  background-color: #f44336;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
  text-align: center;
}

.success {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 4px;
  margin: 10px 0;
  text-align: center;
}

.download-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.preview-container {
  margin-top: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
}

.video-preview {
  margin-bottom: 20px;
}

.video-preview img {
  width: 100%;
  border-radius: 10px;
}

.video-info h3 {
  color: #1e3c72;
  margin-bottom: 10px;
}

.video-info p {
  color: #666;
  margin-bottom: 20px;
}

.download-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.progress-fill {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease;
}

.progress-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 12px;
  font-weight: bold;
} 